import { TCryptoAsset } from "src/types.enums";
import { StringUnion } from "src/utils";

export type TPacket = string | null;

export type TToken = {
  tokenId: string;
  name: string;
  amount: number;
  amountUsd: number;
  currency: TCryptoAsset;
  order: number;
};

export type TBalancesMessage = {
  pendingBalance: number;
  bountyBalance: TToken;
  activeTokens: TToken[];
  tokenWithMaxBalance: TToken;
};

export type TUserMessage = {
  email: string;
  username: string;
  bsvAccountAddress: string;
  provider: string;
  token: string;
  packet: TPacket;
};

export type TAuthorizedMessage = {
  user: TUserMessage;
  balances: TBalancesMessage;
};

export enum AccessStatus {
  LoginRequired = "LoginRequired",
  AuthRequired = "AuthRequired",
  Authenticated = "Authenticated",
}

export const AccessStatuses = StringUnion(
  AccessStatus.LoginRequired,
  AccessStatus.AuthRequired,
  AccessStatus.Authenticated
);

export type TAccessStatus = typeof AccessStatuses.type;

export enum WalletEvent {
  Ready = "READY",
  LoginRequired = "LOGIN_REQUIRED",
  AuthRequired = "AUTH_REQUIRED",
  Authorized = "AUTHORIZED",
  Declined = "DECLINED",
  Closed = "CLOSED",
  TransactionSent = "REFILL_COMPLETED",
  LogoutCompleted = "LOGOUT_COMPLETED",
  Balances = "BALANCES",
  BalancesRefreshed = "BALANCES_REFRESHED",
  BountyBalance = "BOUNTY_BALANCE",
  PendingBalance = "PENDING_BALANCE",
  Error = "ERROR",
  OpenMe = "OPEN_ME",
  OpenLink = "OPEN_LINK",
  Withdrawal = "WITHDRAWAL",
  Deposit = "DEPOSIT",
  StorageRecovered = "STORAGE_RECOVERED",
  SubscribedBalanceChanges = "SUBSCRIBE_BALANCE_CHANGES",
}

export const WalletEvents = StringUnion(
  WalletEvent.Ready,
  WalletEvent.LoginRequired,
  WalletEvent.AuthRequired,
  WalletEvent.Authorized,
  WalletEvent.Declined,
  WalletEvent.Closed,
  WalletEvent.TransactionSent,
  WalletEvent.LogoutCompleted,
  WalletEvent.Balances,
  WalletEvent.BalancesRefreshed,
  WalletEvent.BountyBalance,
  WalletEvent.PendingBalance,
  WalletEvent.Error,
  WalletEvent.OpenMe,
  WalletEvent.OpenLink,
  WalletEvent.Withdrawal,
  WalletEvent.Deposit,
  WalletEvent.StorageRecovered,
  WalletEvent.SubscribedBalanceChanges
);

export type TWalletEvent = typeof AccessStatuses.type;

export enum ClientEvent {
  SendTransaction = "REFILL",
  Logout = "LOGOUT",
  SubscribeBalanceChanges = "SUBSCRIBE_BALANCE_CHANGES",
  Topup = "TOPUP",
  ViewDeposit = "VIEW:DEPOSIT",
  ViewWallet = "VIEW:WALLET",
  RecoverStorage = "RECOVER_STORAGE",
}

export const ClientEvents = StringUnion(
  ClientEvent.SendTransaction,
  ClientEvent.Logout,
  ClientEvent.SubscribeBalanceChanges,
  ClientEvent.Topup,
  ClientEvent.ViewDeposit,
  ClientEvent.ViewWallet,
  ClientEvent.RecoverStorage
);

export type TClientEvent = typeof ClientEvents.type;
