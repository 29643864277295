import { isString } from "lodash";
import { gzip } from "pako";

type TRequest = {
  path: string;
  method: "GET" | "POST" | "PATCH" | "DELETE" | "PUT";
  accessToken?: string;
  data?: unknown;
  withCors?: boolean;
  compress?: boolean;
};

export type TApiResponse<T> = {
  payload?: T;
  error: string;
};

export const call = async <T>({
  path,
  method,
  accessToken,
  data,
  withCors = true,
  compress = false,
}: TRequest): Promise<TApiResponse<T>> => {
  try {
    const headers: HeadersInit = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (accessToken) headers.Authorization = `bearer ${accessToken}`;

    let body: string | Uint8Array | undefined = data
      ? isString(data)
        ? (data as string)
        : JSON.stringify(data)
      : undefined;

    if (compress && body) {
      headers["Content-Encoding"] = "gzip";
      body = gzip(body);
    }

    const request: RequestInit = {
      method,
      headers,
      body,
    };

    if (withCors) {
      request.mode = "cors";
    }

    const rawResponse = await fetch(path, request);

    if (rawResponse.status === 200) {
      const text = await rawResponse.text();
      const payload = text.length > 0 ? JSON.parse(text) : null;

      return { payload, error: "" };
    }

    const error = await rawResponse.text();

    if (error) return { error };
  } catch (e) {
    return { error: `${e}` };
  }

  return { error: "Server error" };
};
