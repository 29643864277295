import { Address } from "dxs-stas-sdk";

export const isBsvAddress = (value: string) => {
  try {
    Address.fromBase58(value);
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const capitalize = (value: string) =>
  value.charAt(0).toUpperCase() + value.slice(1);
