import { envHelper } from "./config-service";

let enableConsoleDebug = !envHelper.isProduction();
let index = 0;

const getDefaultTags = () => ["#debug", "#AppFiorin", `#index-${index++}`];

const logDebug = (...data: any[]) => {
  if (enableConsoleDebug) {
    console.debug(...[...(data ?? []), ...getDefaultTags()]);
  }
};

const logWarn = (...data: any[]) => {
  if (enableConsoleDebug) {
    console.warn(...[...(data ?? []), ...getDefaultTags()]);
  }
};

const logDebugError = (...data: any[]) => {
  if (enableConsoleDebug) {
    console.error(...[...(data ?? []), ...getDefaultTags()]);
  }
};

const logError = (...data: any[]) => {
  console.error(...[...(data ?? []), "#error", ...getDefaultTags()]);
};

export const logService = {
  logDebug,
  logDebugError,
  logError,
  logWarn,
};
