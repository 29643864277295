import {
  Address,
  TransactionBuilderError,
  TransactionReader,
} from "dxs-stas-sdk";
import { getGameAddress } from "src/clients/dxs-client";
import { BsvTokenId } from "src/types.enums";
import {
  envHelper,
  liquidityPoolAddress,
  marginPoolAddress,
} from "./../config-service";
import { number, object, string } from "yup";
import { RootStore } from "../../store";

enum SendToPool {
  MarginPool = "ACCOUNT",
  Liquiditypool = "POOL",
  Gtp = "GTP",
}

const sendRequestPayloadSchema = object().shape({
  amount: number().required(),
  tokenId: string(),
  message: string().required(),
  type: string()
    .required()
    .oneOf(
      Object.values(SendToPool),
      `Type must be one of: [${Object.values(SendToPool).join(", ")}]`
    ),
  gameId: number().test({
    name: "gameId",
    message: "GameId must be specified",
    test: (value, testContext) => {
      return testContext.parent.type === SendToPool.Gtp
        ? value !== undefined
        : true;
    },
  }),
});

let transactionInProgress = false;

export const sendTransaction = async (
  rootStore: RootStore,
  payload: unknown
) => {
  let sendPayload = null;

  if (transactionInProgress) {
    throw new Error("Transaction in progress");
  }

  transactionInProgress = true;

  try {
    const { amount, tokenId, message, type, gameId } =
      await sendRequestPayloadSchema.validate(payload);

    const assetKey =
      rootStore.walletStore.assetKeyByTokenId[tokenId || BsvTokenId];
    const assetConfig = rootStore.walletStore.assetConfigs[assetKey];

    const minAmount = 1 / assetConfig.scheme.SatoshisPerToken;

    if (amount < minAmount) {
      throw Error(`Amount must be greater or equal to ${minAmount}`);
    }

    const {
      userStore: { AccessToken },
    } = rootStore;

    const addressStr =
      type === SendToPool.MarginPool
        ? marginPoolAddress
        : type === SendToPool.Liquiditypool
        ? liquidityPoolAddress
        : type === SendToPool.Gtp
        ? (await getGameAddress(AccessToken, gameId!)).payload ?? ""
        : "";

    const address = Address.fromBase58(addressStr);
    const {
      transactionStore: {
        prepareBsvTransaction,
        prepareStasBundle,
        broadcast,
        setBundleToSend,
        setTransactionToSend,
      },
    } = rootStore;

    const note = message
      ? message.split(" ").map((x: string) => Buffer.from(x, "utf8"))
      : undefined;
    let txRaw: string;

    if (tokenId !== BsvTokenId) {
      const bundle = await prepareStasBundle(
        assetConfig,
        amount,
        address,
        note
      );

      if (bundle.message) {
        throw new Error(
          envHelper.isProduction() ? bundle.message : bundle.devMessage
        );
      }

      setBundleToSend(bundle);

      txRaw = bundle.transactions![bundle.transactions!.length - 1];
    } else {
      const tx = await prepareBsvTransaction(amount, address, note);
      txRaw = tx.toHex();

      setTransactionToSend(tx);
    }

    await broadcast(tokenId);

    const tx = TransactionReader.readHex(txRaw);
    sendPayload = {
      txId: tx.Id,
      // fee: bundle.feeSatoshis, // TODO [Oleg] Ensure no one use it
      amount,
      address: address.Value,
      txs: [tx.Id],
    };

    return sendPayload;
  } catch (error) {
    let message;

    if (error instanceof TransactionBuilderError) {
      if (!envHelper.isProduction()) {
        message = error.devMessage;
      } else {
        message = error.message;
      }
    } else {
      message = error instanceof Error ? error.message : `${error}`;
    }

    console.log(
      "Error in sendTransaction #frameService ",
      "error:",
      error,
      "input payload:",
      payload,
      "sendPayload:",
      sendPayload
    );

    throw new Error(message);
  } finally {
    transactionInProgress = false;
  }
};
