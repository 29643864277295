import { makeAutoObservable, toJS } from "mobx";
import { sleep } from "src/utils";

type TNotification = {
  value: string;
};

enum State {
  hidden,
  visible,
}

export class NotificationStore {
  _notifications: TNotification[] = [];
  _notification?: TNotification;
  _state: State = State.hidden;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get isOpen() {
    return this._state === State.visible;
  }

  get notification() {
    return this._notification ? toJS(this._notification) : undefined;
  }

  close = () => (this._state = State.hidden);

  addNotification = (value: string) => {
    if (this._notifications.length && this._notifications[0].value === value)
      return;

    const notification = { value };
    this._notifications.push(notification);

    if (this._notification === undefined) {
      this._state = State.visible;
      this._notification = notification;
    }
  };

  *removeNotification(): Generator<Promise<void>> {
    this._notifications.shift();
    this._notification = undefined;

    if (this._notifications.length > 0) {
      yield sleep(30);

      if (this._notification === undefined) {
        this._state = State.visible;
        this._notification = this._notifications[0];
      }
    }
  }
}
