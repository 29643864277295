import { dxsApiHost } from "src/services";
import { call } from "./client-helper";

const baseUrl = `${dxsApiHost}/api`;

export const getGameAddress = (accessToken: string, id: number) =>
  call<string>({
    path: `${baseUrl}/games/${id}/address`,
    method: "GET",
    accessToken,
  });
