import { flow, makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

import { RootStore } from "./rootStore";
import { Mnemonic, Wallet } from "dxs-stas-sdk";
import { walletService } from "src/services";
import { mainWalletPath } from "./walletStore";

export class MnemonicStore {
  _mnemonic?: Mnemonic;

  isReady: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      storage: window.localStorage,
      stringify: true,
      name: "MnemonicStore",
      expireIn: 2 * 24 * 60 * 60 * 1000, // two days
      removeOnExpiration: true,
      properties: ["_mnemonic"],
    });

    this.isReady = true;
  }

  get hasMnemonic() {
    return Boolean(this._mnemonic);
  }

  get mnemonic() {
    return this._mnemonic!;
  }

  validateMnemonic = (mnemonic: Mnemonic): boolean => {
    const wallet = Wallet.fromMnemonic(mnemonic.phrase);
    const derived = wallet.derive(mainWalletPath);
    const { hasBsvAccount, BsvAccount } = this.rootStore.walletStore;

    return hasBsvAccount && BsvAccount.Address === derived.Address.Value;
  };

  encodeMnemonicAndStore = flow(function* (
    this: MnemonicStore,
    mnemonic: Mnemonic
  ) {
    const {
      rootStore: {
        walletStore,
        clientStore: { Secret },
      },
    } = this;
    const encodedMnemonic = yield walletService.encodeMnemonic(
      Secret,
      mnemonic
    );

    walletStore._encoded = encodedMnemonic;
    this._mnemonic = undefined;
  });

  setMnemonic = (mnemonic: Mnemonic) => {
    this._mnemonic = mnemonic;
  };
}
