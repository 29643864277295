import { toJS } from "mobx";
import { RootStore } from "../../store";
import { AccessStatus, TAccessStatus } from "./types";

export const getAccessStatus = (rootStore: RootStore): TAccessStatus => {
  const {
    userStore: { AccessToken },
    walletStore: { hasWallet },
    clientStore: { DxsAuthorized },
  } = rootStore;

  if (!toJS(AccessToken) || !toJS(hasWallet)) {
    return AccessStatus.LoginRequired;
  }

  if (!toJS(DxsAuthorized)) {
    return AccessStatus.AuthRequired;
  }

  return AccessStatus.Authenticated;
};
