import { StringUnion } from "./utils";

export const Web3TrustWallet = "trust";
export const Web3MetamaskWallet = "metamask";
export const WcDefaultWallets = StringUnion(
  Web3TrustWallet,
  Web3MetamaskWallet
);
export type TWcDefaultWallets = typeof WcDefaultWallets.type;

export const NetworkAny = "Any";
export const NetworkEthereum = "Ethereum";
export const NetworkPolygon = "Polygon";
export const NetworkTron = "Tron";
export const NetworkBsv = "Bsv";
export const NetworkTon = "Ton";

export const BsvTokenId = "bsv";
export const BsvNetowrk = StringUnion(NetworkBsv);
export type TBsvTokenId = typeof BsvTokenId;
export type TBsvNetwork = typeof BsvNetowrk.type;

export const Web3Networks = StringUnion(
  NetworkAny,
  NetworkEthereum,
  NetworkPolygon,
  NetworkTron,
  NetworkTon
);
export type TWeb3Network = typeof Web3Networks.type;
export type TAllNetworks = TWeb3Network | TBsvNetwork;

export const AssetBsv = "Bsv";
export const AssetUsdxs = "Usdxs";
export const AssetUsdt = "Usdt";
export const AssetUsdc = "Usdc";
export const AssetDai = "Dai";
export const AssetBtc = "Btc";
export const AssetEth = "Eth";
export const AssetTon = "Ton";

export const CryptoAssets = StringUnion(
  AssetUsdxs,
  AssetUsdt,
  AssetUsdc,
  AssetDai,
  AssetBsv,
  AssetBtc,
  AssetEth,
  AssetTon
);
export type TCryptoAsset = typeof CryptoAssets.type;

export const AppThemes = StringUnion("dark", "light");
export type TAppTheme = typeof AppThemes.type;

export const secretSetting = "Secret";
export const dxsAccessSetting = "DxsAccess";
export const pkSetting = "Pk";
export const bountyAddressSetting = "BountyAddress";
export const custodialWalletSetting = "CustodialWallet";

export const ClientSettingNames = StringUnion(
  secretSetting,
  pkSetting,
  bountyAddressSetting,
  dxsAccessSetting,
  custodialWalletSetting
);
export type TClientSettingName = typeof ClientSettingNames.type;

export const Directions = StringUnion("deposit", "withdraw");
export type TDirection = typeof Directions.type;

export const BsvAccountTypes = StringUnion("Main", "Funding");
export type TBsvAccountType = typeof BsvAccountTypes.type;

export const CurrencyPairs = StringUnion(
  "EthUsd",
  "BtcUsd",
  "BsvUsd",
  "MaticUsd",
  "TrxUsd",
  "TonUsd"
);
export type TCurrencyPair = typeof CurrencyPairs.type;

export const BridgeOperations = StringUnion(
  "Transfer",
  "Deposit",
  "Withdraw",
  "CreateAccount"
);
export type TBridgeOperation = typeof BridgeOperations.type;
