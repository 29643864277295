import { flow, makeAutoObservable, toJS } from "mobx";
import { RootStore } from "./rootStore";
import {
  Address,
  FeeRate,
  Mnemonic,
  OutPoint,
  P2pkhBuilder,
  P2stasBuilder,
  ScriptType,
  StasBundleFactory,
  TFundingUtxoRequest,
  TStasPayoutBundle,
  TokenScheme,
  Transaction,
  TransactionBuilder,
  TransactionReader,
  Wallet,
} from "dxs-stas-sdk";
import { TAssetConfig, TBatchBroadcastInitRequest, TContact } from "src/types";
import { BsvTokenId } from "src/types.enums";
import { sliceIntoChunks } from "src/utils";
import { mapValues } from "lodash";
import { envHelper } from "src/services";

export class TransactionStore {
  _recipient: TContact | null = null;
  _amountToSend: number | null = null;
  _bundleToSend: TStasPayoutBundle | null = null;
  _transctionToSend: TransactionBuilder | null = null;
  _batchRequestId: string | null = null;

  _amountToWithdraw: number | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setRecipient = (recipient: TContact | null) => {
    this._recipient = recipient;
  };

  setAmountToSend = (amount: number | null) => {
    this._amountToSend = amount;
  };

  setAmountToWithdraw = (amount: number | null) => {
    this._amountToWithdraw = amount;
  };

  setBundleToSend = (bundle: TStasPayoutBundle | null) => {
    this._bundleToSend = bundle;
  };
  setTransactionToSend = (transaction: TransactionBuilder | null) => {
    this._transctionToSend = transaction;
  };

  get recipient() {
    return this._recipient;
  }

  get amountToSend() {
    return this._amountToSend;
  }

  get amountToWithdraw() {
    return this._amountToWithdraw;
  }

  get bundleToSend() {
    return this._bundleToSend;
  }
  get transactionToSend() {
    return this._transctionToSend;
  }

  prepareBsvTransaction = this.rootStore.blockingCall(
    flow(function* (
      {
        transactionStore: { _getUtxoSet },
        walletStore: { getMnemonicOrThrow, createBsvWallets },
      }: RootStore,
      amount: number,
      to: Address,
      note?: Buffer[]
    ) {
      const satoshis = Math.round(amount * 100_000_000);
      const mnemonic: Mnemonic = yield getMnemonicOrThrow();
      const [main]: Wallet[] = yield createBsvWallets(mnemonic!);
      const utxos: OutPoint[] = yield _getUtxoSet(main.Address.Value);
      const txBuilder = TransactionBuilder.init().addP2PkhOutput(satoshis, to);

      if (note) txBuilder.addNullDataOutput(note);

      let accumulated = 0;

      for (const utxo of utxos) {
        txBuilder.addInput(utxo, main);

        const fee = txBuilder.getFee(FeeRate);

        accumulated += utxo.Satoshis;

        if (accumulated > satoshis + fee) break;
      }

      return txBuilder
        .addChangeOutputWithFee(main.Address, accumulated - satoshis, FeeRate)
        .sign();
    })
  );

  prepareStasBundle = this.rootStore.blockingCall(
    flow(function* (
      { transactionStore: $this, walletStore }: RootStore,
      assetConfig: TAssetConfig,
      amount: number,
      to: Address,
      note?: Buffer[]
    ) {
      const { getFundingUtxo, _getTransactions } = $this;

      const { getMnemonicOrThrow, createBsvWallets } = walletStore;

      const amountSatoshis = Math.round(
        amount * assetConfig.scheme.SatoshisPerToken
      );
      const mnemonic = yield getMnemonicOrThrow();

      const [main, funding]: Wallet[] = yield createBsvWallets(mnemonic!);
      const factory = new StasBundleFactory(
        assetConfig.scheme,
        main,
        funding,
        (request) => getFundingUtxo(funding, to, amountSatoshis, request),
        () => $this._getUtxoSet(main.Address.Value, assetConfig.scheme),
        _getTransactions
      );

      const bundle: TStasPayoutBundle = yield factory.createBundle(
        amountSatoshis,
        to,
        note
      );

      if (!envHelper.isProduction()) {
        if (bundle.devMessage) throw new Error(bundle.devMessage);
      } else {
        if (bundle.message) throw new Error(bundle.message);
      }

      return bundle;
    })
  );

  getFundingUtxo = flow(function* (
    this: TransactionStore,
    feeWallet: Wallet,
    to: Address,
    amountSatoshis: number,
    request: TFundingUtxoRequest
  ) {
    const { utxo, requestId }: { utxo: OutPoint; requestId: string } =
      yield this._getFundingUtxo(feeWallet.Address.Value, {
        To: to.Value,
        AmountSatoshis: amountSatoshis,
        EstimatedFeeSatoshis: request.estimatedFeeSatoshis,
        UtxoIdsToSpend: request.utxoIdsToSpend,
        TransactionsCount: request.transactionsCount,
      });

    this._batchRequestId = requestId;

    return utxo;
  });

  broadcast = this.rootStore.blockingCall(
    flow(function* (
      {
        transactionStore: $this,
        connectionStore,
        notificationStore,
      }: RootStore,
      tokenId?: string
    ) {
      const { bundleToSend, transactionToSend } = $this;

      let broadcasted = false;

      if (tokenId !== BsvTokenId) {
        if (bundleToSend === null || !bundleToSend.transactions?.length)
          throw Error("Create stast bundle first");

        console.log("broadcast", {
          RawTransactions: toJS(bundleToSend),
          RequestId: $this._batchRequestId!,
        });

        broadcasted = yield connectionStore.stasBatchBroadcast({
          RawTransactions: bundleToSend.transactions,
          RequestId: $this._batchRequestId!,
        });

        console.log(broadcasted);
      } else {
        if (!transactionToSend) throw Error("Create transaction first");

        broadcasted = yield connectionStore.broadcast(
          transactionToSend.toHex()
        );
      }

      if (!broadcasted) {
        notificationStore.addNotification("Ask support");
      }
    })
  );

  _getTransactions = async (
    ids: Array<string>
  ): Promise<Record<string, Transaction>> => {
    const batchCount = 50;
    const result: { [id: string]: Transaction } = {};
    const chunks = sliceIntoChunks(ids, batchCount);

    for (const chunk of chunks) {
      const transactions = await this.rootStore.connectionStore.getTransactions(
        chunk
      );

      const batch: { [id: string]: Transaction } = mapValues(
        transactions!,
        (hex) => TransactionReader.readHex(hex)
      );

      for (const id in batch) {
        result[id] = batch[id];
      }
    }

    return result;
  };

  _getUtxoSet = async (
    address: string,
    tokenScheme?: TokenScheme
  ): Promise<OutPoint[]> => {
    const { UtxoSet } = await this.rootStore.connectionStore.getUtxos({
      Address: address,
      TokenId: tokenScheme?.TokenId,
    });

    let lockingScript = tokenScheme
      ? new P2stasBuilder(
          Address.fromBase58(address),
          tokenScheme.TokenId,
          tokenScheme.Symbol
        ).toBuffer()
      : new P2pkhBuilder(Address.fromBase58(address)).toBuffer();

    return UtxoSet.map(
      (x) =>
        new OutPoint(
          x.TxId,
          x.Vout,
          lockingScript,
          x.Satoshis,
          Address.fromBase58(x.Address),
          tokenScheme ? ScriptType.p2stas : ScriptType.p2pkh
        )
    );
  };

  _getFundingUtxo = async (
    address: string,
    request: TBatchBroadcastInitRequest
  ): Promise<{ utxo: OutPoint; requestId: string }> => {
    const adr = Address.fromBase58(address);
    const lockingScript = new P2pkhBuilder(adr).toBuffer();
    const { FeeTxId, FeeVout, RequestId } =
      await this.rootStore.connectionStore.stasBatchInit(request);

    return {
      utxo: new OutPoint(
        FeeTxId,
        FeeVout,
        lockingScript,
        request.EstimatedFeeSatoshis,
        adr,
        ScriptType.p2pkh
      ),
      requestId: RequestId,
    };
  };

  // // @ts-ignore
  // window.prep = async (amount: number, to: string) => {
  //   const bundle = await this.prepareStasBundle(
  //     amount,
  //     Address.fromBase58(to)
  //   );
  //   this.setBundleToSend(bundle);

  //   return bundle;
  // };
  // // @ts-ignore
  // window.broad = this.broadcast;
  // // @ts-ignore
  // window.send = async (amount: number, to: string) => {
  //   // @ts-ignore
  //   await window.prep(amount, to); // @ts-ignore
  //   await window.broad();
  // };
  // // @ts-ignore
  // window.sendN = async (n: number, amount: number, to: string) => {
  //   for (let i = 0; i < n; i++) {
  //     // @ts-ignore
  //     await window.send(amount, to);
  //   }
  // };
}
