import { Mnemonic, toHexBuffer, toUtf8Buffer, Wallet } from "dxs-stas-sdk";
import { decrypt, encrypt, utils } from "micro-aes-gcm";

const getWallets = async (mnemonic: Mnemonic, paths: string[]) => {
  const master = Wallet.fromMnemonic(mnemonic.phrase);

  return paths.map((x) => master.derive(x));
};

const encodeMnemonic = async (
  secret: string,
  mnemonic: Mnemonic
): Promise<string> => {
  const encripted = Buffer.from(
    await encrypt(toUtf8Buffer(secret), utils.utf8ToBytes(mnemonic.phrase))
  );
  const encodedMnemonic = encripted.toString("hex");

  return encodedMnemonic;
};

const decodeMnemonic = async (
  secret: string,
  encodedMnemonic: string
): Promise<Mnemonic> => {
  const encripted = toHexBuffer(encodedMnemonic);
  const phraseUtf8Bytes = await decrypt(toUtf8Buffer(secret), encripted);
  const phrase = utils.bytesToUtf8(phraseUtf8Bytes);

  return Mnemonic.fromPhrase(phrase);
};

export const walletService = {
  getWallets,
  encodeMnemonic,
  decodeMnemonic,
};
