import {
  DaiContractAddress,
  UsdcContractAddress,
  UsdtContractAddress,
} from "src/services";
import { EtherUnits } from "web3-utils";

export class JsonRpcError {
  code!: number;
  message!: string;
  data?: unknown;
}

export const errorCodes = {
  rpc: {
    invalidInput: -32000,
    resourceNotFound: -32001,
    resourceUnavailable: -32002,
    transactionRejected: -32003,
    methodNotSupported: -32004,
    limitExceeded: -32005,
    parse: -32700,
    invalidRequest: -32600,
    methodNotFound: -32601,
    invalidParams: -32602,
    internal: -32603,
  },
  provider: {
    userRejectedRequest: 4001,
    unauthorized: 4100,
    unsupportedMethod: 4200,
    disconnected: 4900,
    chainDisconnected: 4901,
    chainDoesnotExists: 4902,
  },
};

export const errorValues = {
  "-32700": {
    standard: "JSON RPC 2.0",
    message:
      "Invalid JSON was received by the server. An error occurred on the server while parsing the JSON text.",
  },
  "-32600": {
    standard: "JSON RPC 2.0",
    message: "The JSON sent is not a valid Request object.",
  },
  "-32601": {
    standard: "JSON RPC 2.0",
    message: "The method does not exist / is not available.",
  },
  "-32602": {
    standard: "JSON RPC 2.0",
    message: "Invalid method parameter(s).",
  },
  "-32603": {
    standard: "JSON RPC 2.0",
    message: "Internal JSON-RPC error.",
  },
  "-32000": {
    standard: "EIP-1474",
    message: "Invalid input.",
  },
  "-32001": {
    standard: "EIP-1474",
    message: "Resource not found.",
  },
  "-32002": {
    standard: "EIP-1474",
    message: "Resource unavailable.",
  },
  "-32003": {
    standard: "EIP-1474",
    message: "Transaction rejected.",
  },
  "-32004": {
    standard: "EIP-1474",
    message: "Method not supported.",
  },
  "-32005": {
    standard: "EIP-1474",
    message: "Request limit exceeded.",
  },
  "4001": {
    standard: "EIP-1193",
    message: "User rejected the request.",
  },
  "4100": {
    standard: "EIP-1193",
    message:
      "The requested account and/or method has not been authorized by the user.",
  },
  "4200": {
    standard: "EIP-1193",
    message: "The requested method is not supported by this Ethereum provider.",
  },
  "4900": {
    standard: "EIP-1193",
    message: "The provider is disconnected from all chains.",
  },
  "4901": {
    standard: "EIP-1193",
    message: "The provider is disconnected from the specified chain.",
  },
};

export class ConnectInfo {
  chainId!: string;
}

export const transferAbi = [
  {
    constant: false,
    inputs: [
      { name: "to", type: "address" },
      { name: "value", type: "uint256" },
    ],
    name: "transfer",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const balanceOfAbi = [
  {
    constant: true,
    inputs: [
      {
        name: "owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

// export const getErc20BalanceOfContract = (address: string) =>
//   new Contract(
//     [
//       {
//         constant: true,
//         inputs: [
//           {
//             name: "_owner",
//             type: "address",
//           },
//         ],
//         name: "balanceOf",
//         outputs: [
//           {
//             name: "balance",
//             type: "uint256",
//           },
//         ],
//         payable: false,
//         type: "function",
//       },
//     ],
//     address
//   );

// export const getErc20TransferEncodedFunction = (to: string, amount: string) =>
//   encodeFunctionCall(
//     {
//       constant: false,
//       inputs: [
//         { name: "_to", type: "address" },
//         { name: "_value", type: "uint256" },
//       ],
//       name: "transfer",
//       outputs: [],
//       payable: false,
//       stateMutability: "nonpayable",
//       type: "function",
//     },
//     [to, amount]
//   );

export const tokensMap: {
  [name: string]: {
    address: THexString;
    unit: EtherUnits;
  };
} = {
  Usdt: {
    address: UsdtContractAddress,
    unit: "mwei",
  },
  Usdc: {
    address: UsdcContractAddress,
    unit: "mwei",
  },
  Dai: {
    address: DaiContractAddress,
    unit: "ether",
  },
};

export const toHexChainId = (chainId: number): string =>
  `0x${chainId.toString(16)}`;

// export const chainsMap: {
//   [name: string]: {
//     chainId: string;
//     chainName: string;
//     rpcUrls: string[];
//     blockExplorerUrls: string[];
//     nativeCurrency?: {
//       symbol: string;
//       decimals: number;
//     };
//   };
// } = {
//   "0x1": {
//     chainId: "0x1",
//     chainName: "Ethereum Mainnet",
//     rpcUrls: ["https://api.infura.io/jsonrpc/mainnet"],
//     blockExplorerUrls: ["https://etherscan.io"],
//   },
//   "0xaa36a7": {
//     chainId: "0xaa36a7",
//     chainName: "Sepolia Testnet",
//     rpcUrls: [
//       "https://rpc.sepolia.org",
//       "https://rpc2.sepolia.org",
//       "https://rpc-sepolia.rockx.com",
//     ],
//     blockExplorerUrls: ["https://sepolia.etherscan.io"],
//     nativeCurrency: {
//       symbol: "sepETH",
//       decimals: 18,
//     },
//   },
// };
