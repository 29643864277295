export const unwrapError = (error: unknown) => {
  let message = "";

  if (error instanceof Error) {
    message = error.message;
  } else {
    try {
      message = JSON.stringify(error, null, 2);
    } catch {
      message = `${error}`;
    }
  }

  return message;
};
