import { TransactionExecutionError } from "viem";
import { JsonRpcError } from "./ethereum-utils";

export const isString = (data: unknown): data is string => {
  return typeof data === "string";
};

export const isHexString = (value: string): value is THexString =>
  value.startsWith("0x");

export const isArrayOfStrings = (value: unknown): value is string[] =>
  Array.isArray(value) && value.every((item) => typeof item === "string");

export const isJsonRpcError = (value: unknown): value is JsonRpcError =>
  (value as JsonRpcError).code !== undefined &&
  (value as JsonRpcError).message !== undefined;

export const isTransactionExecutionError = (
  value: unknown
): value is TransactionExecutionError =>
  (value as TransactionExecutionError).name === "TransactionExecutionError";

// TypeScript will infer a string union type from the literal values passed to
// this function. Without `extends string`, it would instead generalize them
// to the common string type.
export const StringUnion = <UnionType extends string>(
  ...values: UnionType[]
) => {
  Object.freeze(values);

  const valueSet: Set<string> = new Set(values);

  const guard = (value: string): value is UnionType => {
    return valueSet.has(value);
  };

  const check = (value: string): UnionType => {
    if (!guard(value)) {
      const actual = JSON.stringify(value);
      const expected = values.map((s) => JSON.stringify(s)).join(" | ");
      throw new TypeError(
        `Value '${actual}' is not assignable to type '${expected}'.`
      );
    }
    return value;
  };

  const unionNamespace = { guard, check, values };

  return Object.freeze(
    unionNamespace as typeof unionNamespace & { type: UnionType }
  );
};
