import {
  entries,
  flow,
  get,
  makeAutoObservable,
  reaction,
  set,
  values,
  when,
} from "mobx";
import {
  clearPersistedStore,
  isHydrated,
  makePersistable,
} from "mobx-persist-store";
import { consigliereClient, TApiResponse, TBridgeConfig } from "src/clients";
import { walletService } from "src/services";
import { Address, Mnemonic, TokenScheme, Wallet } from "dxs-stas-sdk";

import { RootStore } from "./rootStore";
import {
  AssetBsv,
  CryptoAssets,
  Web3Networks,
  NetworkBsv,
  TAllNetworks,
  TCryptoAsset,
  TWeb3Network,
  pkSetting,
} from "src/types.enums";
import {
  TBsvAccount,
  TWeb3Account,
  TAssetConfig,
  TAsset,
  TBalanceResponse,
  TBalance,
  TCreateBsvAccountResponse,
} from "src/types";
import { BsvTokenId } from "src/types.enums";

export const mainWalletPath = "m/44'/236'/0'/0/0";
export const fudingWalletPath = "m/44'/236'/0'/0/1";

export const getAssetKey = (network: TAllNetworks, cryptoAsset: TCryptoAsset) =>
  `asset/${network}/${cryptoAsset}`;

export const bsvAssetKey = getAssetKey("Bsv", "Bsv");

export class WalletStore {
  _encoded?: string;

  assetConfigs: {
    [assetKey: string]: TAssetConfig;
  } = {};
  assetKeyByTokenId: { [tokenId: string]: string } = {};
  accounts: { [assetKey: string]: TWeb3Account } = {};
  balances: { [token: string]: number } = {
    [BsvTokenId]: 0,
  };

  bsvAccount?: TBsvAccount;
  bountyTokenId!: string;
  bountyBalance: number = 0;

  balancesChanged = 1;

  isReady: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      storage: window.localStorage,
      stringify: true,
      name: "WalletStore",
      properties: [
        "_encoded",
        "assetConfigs",
        "accounts",
        "bsvAccount",
        "bountyTokenId",
        "bountyBalance",
        "balances",
      ],
    });

    reaction(
      () => this.rootStore.userStore.IsLoggedOut,
      async (isLoggedOut) => {
        if (!isLoggedOut) return;

        this._encoded = undefined;
        this.accounts = {};
        this.balances = {
          [BsvTokenId]: 0,
        };
        this.bsvAccount = undefined;
        this.bountyBalance = 0;

        await clearPersistedStore(this);
      },
      { fireImmediately: true }
    );
  }

  get hasWallet() {
    return Boolean(this._encoded);
  }

  get hasBsvAccount() {
    return Boolean(this.bsvAccount);
  }

  get BsvAccount() {
    return this.bsvAccount!;
  }

  get totalUsdBalance() {
    return this.tokensWithBalance.reduce((a, x) => a + x.balanceUsd, 0);
  }

  get tokenIds(): string[] {
    return values(this.assetConfigs).map(({ scheme: { TokenId } }) => TokenId);
  }

  get listAssets(): TAsset[] {
    const { toAmount, getInUsd } = this;

    const balances: ReadonlyArray<[string, number]> = entries(this.balances);
    const assets: ReadonlyArray<[string, TAssetConfig]> = entries(
      this.assetConfigs
    );

    return assets.map(([assetKey, config]) => {
      const satoshis =
        balances.find(([tokenId]) => tokenId === config.scheme.TokenId)?.[1] ??
        0;
      const balance = toAmount(config.network, config.cryptoAsset, satoshis);
      const balanceUsd = getInUsd(config.cryptoAsset, balance);

      return { key: assetKey, config, balance, balanceUsd };
    });
  }

  get tokenWithMaxBalance(): TAsset {
    let balance = 0;
    let result: TAsset = {
      key: getAssetKey("Polygon", "Usdt"),
      config: get(this.assetConfigs, getAssetKey("Polygon", "Usdt")),
      balance: 0,
      balanceUsd: 0,
    };

    this.listAssets.forEach((asset) => {
      if (asset.balance > balance) {
        result = asset;
        balance = asset.balance;
      }
    });

    return result;
  }

  get tokensWithBalance(): TAsset[] {
    return this.listAssets
      .filter((x) => x.balance > 0)
      .sort((x, y) => y.balanceUsd - x.balanceUsd);
  }

  get tokensZeroBalances(): TAsset[] {
    return this.listAssets
      .filter((x) => x.balance === 0)
      .sort((x, y) => x.config.order - y.config.order);
  }

  get maxAvailable() {
    let assetKey: string | undefined;
    let web3Account: TWeb3Account | undefined;

    entries(this.accounts).forEach(([key, acc]) => {
      if (!acc?.AvailableBalance) return;

      if (
        !web3Account ||
        web3Account.AvailableBalance < acc?.AvailableBalance
      ) {
        assetKey = key;
        web3Account = acc;
      }
    });

    return { assetKey, web3Account };
  }

  getIsUsdToken = (asset: TCryptoAsset) => {
    if (asset === "Bsv") return false;
    if (asset === "Btc") return false;
    if (asset === "Eth") return false;

    return true;
  };

  getInUsd = (asset: TCryptoAsset, amount: number) => {
    if (asset === "Bsv") return amount * this.rootStore.ratesStore.bsvRate;
    if (asset === "Btc") return amount * this.rootStore.ratesStore.btcRate;
    if (asset === "Eth") return amount * this.rootStore.ratesStore.ethRate;

    return amount;
  };

  getAccount = (
    network: TAllNetworks,
    cryptoAsset: TCryptoAsset
  ): TWeb3Account => get(this.accounts, getAssetKey(network, cryptoAsset));

  getBalance = (network: TAllNetworks, cryptoAsset: TCryptoAsset) => {
    const assetKey = getAssetKey(network, cryptoAsset);
    const assetConfig: TAssetConfig = get(this.assetConfigs, assetKey);
    const satoshis = get(this.balances, assetConfig.scheme.TokenId) || 0;

    return this.toAmount(network, cryptoAsset, satoshis);
  };

  getAssetConfig = (
    network: TAllNetworks,
    cryptoAsset: TCryptoAsset
  ): { assetConfig: TAssetConfig; withdrawVia: TAssetConfig } => {
    const assetKey = getAssetKey(network, cryptoAsset);
    const assetConfig: TAssetConfig = get(this.assetConfigs, assetKey);
    let withdrawVia = assetConfig;

    const max = this.maxAvailable;

    if (cryptoAsset === "Usdxs" && max.assetKey) {
      withdrawVia = get(this.assetConfigs, max.assetKey);
    }

    return { assetConfig, withdrawVia };
  };

  init = flow(function* (this: WalletStore) {
    yield when(() => isHydrated(this));
    yield when(() => this.rootStore.userStore.isReady);

    const { payload: bridgeConfig }: TApiResponse<TBridgeConfig> =
      yield consigliereClient.getBridgeConfig();

    this.bountyTokenId = bridgeConfig!.bountyTokenId;

    const allAssets = bridgeConfig!.assets;
    const orders = bridgeConfig!.orders;

    const unordered: {
      assetKey: string;
      assetConfig: TAssetConfig;
    }[] = [
      {
        assetKey: getAssetKey("Bsv", "Bsv"),
        assetConfig: {
          scheme: new TokenScheme("BSV", BsvTokenId, "BSV", 100_000_000),
          order: orders[NetworkBsv][AssetBsv],
          cryptoAsset: "Bsv",
          network: "Bsv",
        },
      },
    ];

    Object.keys(allAssets).forEach((web3Network: string) => {
      const network = Web3Networks.check(web3Network);
      const networkAssets = allAssets[network];

      Object.keys(networkAssets).forEach((asset: string) => {
        const cryptoAsset = CryptoAssets.check(asset);
        const assetConfig = networkAssets[asset];

        const scheme = new TokenScheme(
          assetConfig!.name,
          assetConfig!.tokenId,
          assetConfig!.symbol,
          assetConfig!.satoshisPerToken
        );

        unordered.push({
          assetKey: getAssetKey(network, cryptoAsset),
          assetConfig: {
            scheme,
            order: orders[web3Network][cryptoAsset],
            network,
            cryptoAsset,
          },
        });
      });
    });

    unordered
      .sort((a, b) => a.assetConfig.order - b.assetConfig.order)
      .forEach(({ assetKey, assetConfig }) => {
        set(this.assetConfigs, assetKey, assetConfig);
        set(this.assetKeyByTokenId, assetConfig.scheme.TokenId, assetKey);
      });

    this.isReady = true;
  });

  load = this.rootStore.blockingCall(
    flow(function* (root: RootStore) {
      yield root.walletStore.ensureStoredMnemonicIsCorrect();
    })
  );

  ensureStoredMnemonicIsCorrect = flow(function* (this: WalletStore) {
    if (!this._encoded) return;

    let valid = true;
    try {
      const mnemonic = yield this.getMnemonicOrThrow();

      if (this.hasBsvAccount) {
        const { main }: { main: string; funding: string } =
          yield this.getBsvAddresses(mnemonic);

        if (main !== this.BsvAccount.Address) {
          console.error("Stored mnemonic doesn't match with bsvAccount");

          valid = false;
        }
      }
    } catch (error) {
      console.log(error);

      valid = false;
    }

    if (!valid) {
      this._encoded = undefined;
    }
  });

  getMnemonicOrThrow = flow<Mnemonic, []>(function* (this: WalletStore) {
    try {
      return yield walletService.decodeMnemonic(
        this.rootStore.clientStore.Secret,
        this._encoded!
      );
    } catch (error) {
      this._encoded = undefined;
      throw Error("Ensure mnemonic is incorrect");
    }
  });

  setPk = this.rootStore.singleCall<boolean, [Mnemonic]>(
    "setPk",
    flow(function* (
      {
        mnemonicStore,
        connectionStore: { createBsvAccounts },
        walletStore,
      }: RootStore,
      mnemonic: Mnemonic
    ) {
      if (!walletStore.hasBsvAccount) {
        try {
          const { main, funding }: { main: string; funding: string } =
            yield walletStore.getBsvAddresses(mnemonic);

          const response: TCreateBsvAccountResponse = yield createBsvAccounts({
            Address: main,
            FundingAddress: funding,
          });

          if (!response) return false;

          walletStore.bsvAccount = response.Main;
        } catch {
          return false;
        }
      }

      yield mnemonicStore.encodeMnemonicAndStore(mnemonic);

      return true;
    })
  );

  createPkAndSave = this.rootStore.blockingCall(
    this.rootStore.singleCall(
      "createPkAndSave",
      flow(function* ({
        connectionStore,
        clientStore: { Secret },
        walletStore,
      }: RootStore) {
        const mnemonic = Mnemonic.generate();
        const encodedMnemonic: string = yield walletService.encodeMnemonic(
          Secret,
          mnemonic
        );

        yield connectionStore.updateSetting({
          Type: pkSetting,
          Value: encodedMnemonic,
        });
        yield walletStore.setPk(mnemonic);
      })
    )
  );

  createWeb3Account = this.rootStore.singleCall(
    "createWeb3Account",
    flow(function* (
      rootStore: RootStore,
      withdrawAddress: string,
      web3Network: TWeb3Network,
      cryptoAsset: TCryptoAsset
    ) {
      const {
        walletStore,
        notificationStore: { addNotification },
        connectionStore,
      } = rootStore;

      try {
        const account = yield connectionStore.createWeb3Account({
          Web3Network: web3Network,
          CryptoAsset: cryptoAsset,
          WithdrawAddress: withdrawAddress,
        });

        if (!account) {
          addNotification("Ask support");
          return;
        }

        const assetKey = getAssetKey(web3Network, cryptoAsset);

        set(walletStore.accounts, assetKey, account);

        return true;
      } catch {
        return false;
      }
    })
  );

  getRedeemAddress = (tokenId: string) => Address.fromHash160Hex(tokenId);

  toAmount = (
    network: TAllNetworks,
    cryptoAsset: TCryptoAsset,
    satoshis: number
  ) => {
    const {
      scheme: { SatoshisPerToken },
    } = this.assetConfigs[getAssetKey(network, cryptoAsset)];

    return satoshis / SatoshisPerToken;
  };

  createBsvWallets = flow<Wallet[], [Mnemonic]>(function* (
    this: WalletStore,
    mnemonic: Mnemonic
  ) {
    const [main, funding]: Wallet[] = yield walletService.getWallets(mnemonic, [
      mainWalletPath,
      fudingWalletPath,
    ]);

    return [main, funding];
  });

  getBsvAddresses = flow(function* (this: WalletStore, mnemonic: Mnemonic) {
    const [main, funding] = yield this.createBsvWallets(mnemonic);

    return { main: main.Address.Value, funding: funding.Address.Value };
  });

  refreshBalances = this.rootStore.singleCall(
    "refreshBalances",
    flow(function* (rootStore: RootStore) {
      const {
        userStore: { IsLoggedOut },
        clientStore: { BountyAddress },
        connectionStore,
        walletStore: $this,
        walletStore: { bountyTokenId, tokenIds, hasBsvAccount, BsvAccount },
      } = rootStore;

      if (IsLoggedOut || !hasBsvAccount) return;

      const addresses = [BsvAccount.Address];

      let noBsvTokenIds = tokenIds.filter((x) => x !== BsvTokenId);

      if (BountyAddress) {
        addresses.push(BountyAddress);
        noBsvTokenIds = [...noBsvTokenIds, bountyTokenId];
      }

      const balances: TBalanceResponse = yield connectionStore.getBalance({
        Addresses: addresses,
        TokenIds: noBsvTokenIds,
      });

      if (balances) {
        const tokenIdsWithBalance = new Set<string>();

        for (const balance of balances) {
          if (balance.TokenId) {
            if (balance.TokenId === $this.bountyTokenId) {
              if (balance.Address === BountyAddress) {
                $this.bountyBalance = balance.Satoshis;

                tokenIdsWithBalance.add(balance.TokenId);
              }
            } else {
              if (balance.Address === BsvAccount.Address) {
                set($this.balances, balance.TokenId, balance.Satoshis);

                tokenIdsWithBalance.add(balance.TokenId);
              }
            }
          } else {
            if (balance.Address === BsvAccount.Address) {
              set($this.balances, BsvTokenId, balance.Satoshis);
            }
          }
        }

        noBsvTokenIds
          .filter((x) => !tokenIdsWithBalance.has(x))
          .forEach((tokenId) => {
            if (tokenId === bountyTokenId) {
              $this.bountyBalance = 0;
            } else {
              set($this.balances, tokenId, 0);
            }
          });

        $this.balancesChanged = $this.balancesChanged * -1;
      }
    })
  );

  onBsvAccountChanged = (bsvAccount: TBsvAccount) => {
    this.bsvAccount = bsvAccount;
  };

  onWeb3AccountChanged = (web3Account: TWeb3Account) => {
    const key = getAssetKey(web3Account.CryptoNetwork, web3Account.CryptoAsset);
    set(this.accounts, key, web3Account);
  };

  onBalanceChange = async (balance: TBalance) => {
    console.log("onBalanceChange", { balance });

    if (!balance) {
      await this.refreshBalances();
      await this.rootStore.historyStore.reload();

      return;
    }

    if (balance.TokenId === this.bountyTokenId) {
      this.bountyBalance = balance.Satoshis;
    } else {
      set(this.balances, balance.TokenId ?? BsvTokenId, balance.Satoshis);
    }

    this.balancesChanged = this.balancesChanged * -1;
  };
}
