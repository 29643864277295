import { consigliereHost } from "src/services";
import { call } from "./client-helper";

const baseUrl = `${consigliereHost}/api`;

export type TBridgeConfig = {
  assets: {
    [network: string]: {
      [asset: string]: {
        name: string;
        tokenId: string;
        symbol: string;
        satoshisPerToken: number;
      };
    };
  };
  orders: { [network: string]: { [asset: string]: number } };
  bountyTokenId: string;
};

const getBridgeConfig = () =>
  call<TBridgeConfig>({
    path: `${baseUrl}/configuration`,
    method: "GET",
  });

export const consigliereClient = {
  getBridgeConfig,
};
