import {
  CHAIN,
  THEME,
  TonConnectUI,
  TonProofItemReplySuccess,
  WalletsModalState,
} from "@tonconnect/ui";
import { ownHost, TonChainId } from "./config-service";
import { identityClient, TTonOwnershipProofRequest } from "src/clients";
import { toNanoton } from "src/utils";

export type TTonConnectRequest = {
  onSuccess: (token: string, address: string) => Promise<void>;
  OnCancel: () => void;
  onError: (message: string) => void;
};

const isProofReplySuccess = (value: any): value is TonProofItemReplySuccess =>
  value?.name === "ton_proof";

const getWeb3AuthToken = async () => {
  const response = await identityClient.getTonNonce();

  if (response.error) {
    throw response.error;
  }

  return response.payload!;
};

let tonConnectUI: TonConnectUI;

const getTonConnectUI = () => {
  if (!tonConnectUI) {
    tonConnectUI = new TonConnectUI({
      manifestUrl: `${ownHost}/tonconnect-manifest.json`,
      uiPreferences: {
        theme: THEME.DARK,
      },
    });
  }

  return tonConnectUI;
};

const isWalletConnected = () => getTonConnectUI().connected ?? false;

const attachEvents = async ({
  onSuccess,
  OnCancel,
  onError,
}: TTonConnectRequest) => {
  return getTonConnectUI().onModalStateChange(
    async ({ status, closeReason }: WalletsModalState) => {
      console.log("tonConnectUI", { status, closeReason });

      // possible states:
      // {"status":"opened","closeReason":null}
      // {"status":"closed","closeReason":"action-cancelled"}
      // {"status":"closed","closeReason":"wallet-selected"}

      if (status === "closed") {
        if (closeReason === "action-cancelled") {
          // close and unsubscribe
          OnCancel();
        } else if (closeReason === "wallet-selected") {
          if (getTonConnectUI().connected) {
            const wallet = getTonConnectUI().wallet!;
            const account = wallet.account;

            if (account.chain !== TonChainId) {
              onError(
                account.chain === CHAIN.MAINNET
                  ? "Switch to testnet"
                  : "Switch to mainnet"
              );
            }

            const tonProof = wallet.connectItems?.tonProof;

            if (!isProofReplySuccess(tonProof)) {
              return;
            }

            const checkProofRequest: TTonOwnershipProofRequest = {
              address: account.address,
              chain: account.chain,
              publicKey: account.publicKey,
              timestamp: tonProof.proof.timestamp,
              signature: tonProof.proof.signature,
              payload: tonProof.proof.payload,
              walletStateInit: account.walletStateInit,
              domain: tonProof.proof.domain.value,
            };

            const { payload, error } = await identityClient.checkTonProof(
              checkProofRequest
            );
            console.log({
              proofAfter: checkProofRequest,
              result: payload,
              wallet,
            });

            if (error) {
              onError(error);
            }
            onSuccess(payload!.token, checkProofRequest.address);
          } else {
            onError("Failed to connect to the wallet");
          }
        }
      } else if (status === "opened") {
        console.log({ status, closeReason });
      } else {
        onError(
          `Invalid TonConnect state: ${status}/${closeReason ?? "unknown"}`
        );
      }
    }
  );
};

const setServerNonce = (nonce: string) => {
  getTonConnectUI().setConnectRequestParameters({
    state: "ready",
    value: {
      tonProof: nonce,
    },
  });
};

const block = () => {
  getTonConnectUI().setConnectRequestParameters({
    state: "loading",
  });
};

// const ensureConnected = ()=> {

// }

const connectTonWallet = async ({
  onError,
  onSuccess,
  OnCancel,
}: TTonConnectRequest) => {
  let timer: ReturnType<typeof setTimeout>;

  const setNonce = async () => {
    const { nonce, expireAtSec } = await getWeb3AuthToken();

    setServerNonce(nonce);

    const expireAt = new Date(expireAtSec * 1000);
    const now = new Date();
    const diffSeconds = expireAt.getTime() - now.getTime();

    timer = setTimeout(setNonce, diffSeconds);
  };

  block();

  const disposable = await attachEvents({
    onError: (message) => {
      onError(message);
      clearTimeout(timer);
    },
    onSuccess: async (token, address) => {
      await onSuccess(token, address);
      clearTimeout(timer);
    },
    OnCancel: () => {
      OnCancel();
      clearTimeout(timer);
    },
  });

  const connector = getTonConnectUI();
  const connectionRestored = await connector.connectionRestored;

  if (connectionRestored) {
    await tonConnectUI.disconnect();
  }

  await connector.openModal();
  await setNonce();

  return disposable;
};

async function sendTransaction({
  address,
  payload,
}: {
  address: string;
  payload?: string;
}) {
  try {
    const connector = getTonConnectUI();
    const connectionRestored = await connector.connectionRestored;

    if (!connectionRestored || !connector.connected) {
      console.log("no conected");
      return;
    }

    const result = await connector.sendTransaction(
      {
        validUntil: Math.floor(Date.now() / 1000) + 300, // 5 minutes
        network: TonChainId,
        messages: [
          {
            address: address,
            amount: toNanoton(0.05).str,
            payload,
          },
        ],
      },
      {
        modals: ["before", "success", "error"],
        notifications: ["before", "success", "error"],
      }
    );

    return result.boc;
  } catch (e) {
    console.error(e);
  }
}

export const tonService = {
  connectTonWallet,
  sendTransaction,
  isWalletConnected,
};
