import { get, makeAutoObservable, set } from "mobx";
import { RootStore } from "./rootStore";
import { TAllNetworks, TBridgeOperation, TCryptoAsset } from "src/types.enums";
import { TBridgeFees, TCompensationDto } from "src/types";

const getOpFeeKey = (
  web3Network: TAllNetworks,
  bridgeOperaion: TBridgeOperation
) => `${web3Network}/${bridgeOperaion}`;

const getOpAssetKey = (
  web3Network: TAllNetworks,
  cryptoAsset: TCryptoAsset,
  bridgeOperaion: TBridgeOperation
) => `${web3Network}/${cryptoAsset}/${bridgeOperaion}`;

export class CompensationsStore {
  bridgeFees: { [key: string]: number } = {};

  compensations: {
    [key: string]: TCompensationDto;
  } = {};

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setOperationsFees = ({
    CryptoNetwork,
    Transfer,
    Deposit,
    Withdraw,
    CreateAccount,
  }: TBridgeFees) => {
    set(this.bridgeFees, getOpFeeKey(CryptoNetwork, "Transfer"), Transfer);
    set(this.bridgeFees, getOpFeeKey(CryptoNetwork, "Deposit"), Deposit);
    set(this.bridgeFees, getOpFeeKey(CryptoNetwork, "Withdraw"), Withdraw);
    set(
      this.bridgeFees,
      getOpFeeKey(CryptoNetwork, "CreateAccount"),
      CreateAccount
    );
  };

  setCompensation = (compensation: TCompensationDto) => {
    set(
      this.compensations,
      getOpAssetKey(
        compensation.CryptoNetwork,
        compensation.CryptoAsset,
        compensation.BridgeOperation
      ),
      compensation
    );
  };

  getOperationFee = (
    web3Network: TAllNetworks,
    operation: TBridgeOperation
  ): number => get(this.bridgeFees, getOpFeeKey(web3Network, operation));

  getCompensation = (
    web3Network: TAllNetworks,
    cryptoAsset: TCryptoAsset,
    operation: TBridgeOperation
  ): TCompensationDto => {
    return get(
      this.compensations,
      getOpAssetKey(web3Network, cryptoAsset, operation)
    );
  };

  getBridgeFee = (
    cryptoNetwork: TAllNetworks,
    cryptoAsset: TCryptoAsset,
    operation: TBridgeOperation
  ) => {
    if (cryptoNetwork === "Bsv" || cryptoNetwork === "Any") return 0;

    const fee = this.getOperationFee(cryptoNetwork, operation);
    const compensation = this.getCompensation(
      cryptoNetwork,
      cryptoAsset,
      operation
    );

    return Math.max(fee - compensation.CompensationLimit, 0);
  };

  getBountyReward = (
    network: TAllNetworks,
    cryptoAsset: TCryptoAsset,
    operation: TBridgeOperation
  ) => {
    const fee = this.getBridgeFee(network, cryptoAsset, operation);

    if (fee === 0) return 0;

    const { BountyMultiplier } = this.getCompensation(
      network,
      cryptoAsset,
      operation
    );

    return fee * BountyMultiplier;
  };
}
