import { flow, makeAutoObservable, reaction } from "mobx";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from "@microsoft/signalr";
import { MessagePackHubProtocol } from "@microsoft/signalr-protocol-msgpack";
import { consigliereHost } from "src/services";
import { RootStore } from "./rootStore";
import {
  TAddContactRequest,
  TBalanceRequest,
  TBalanceResponse,
  TBatchBroadcastInitRequest,
  TBatchBroadcastInitResponse,
  TBatchBroadcastSendRequest,
  TContact,
  TCreateBsvAccountRequest,
  TCreateBsvAccountResponse,
  TCreateTonTransactionPayloadRequest,
  TCreateTonTransactionPayloadResponse,
  TCreateWeb3AccountRequest,
  THistoryRequest,
  THistoryResponse,
  TOrderedPageRequest,
  TTransactionsResponse,
  TUpdateSettingRequest,
  TUtxoSetRequest,
  TUtxoSetResponse,
  TWeb3Account,
} from "src/types";
import { isString } from "lodash";

let ownLock = 0;

export class ConnectionStore {
  isReady: boolean = false;
  connected: boolean = false;

  _connection!: HubConnection;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  init = () => {
    if (this.isReady) return;

    this._connection = new HubConnectionBuilder()
      .withUrl(`${consigliereHost}/ws/app`, {
        accessTokenFactory: () => this.rootStore.userStore.AccessToken,
        withCredentials: false,
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: () => Math.random() * 1000,
      })
      .configureLogging(LogLevel.Critical)
      .withHubProtocol(new MessagePackHubProtocol())
      .build();

    this._atachHandlers();

    this._connection.onclose(this._onDisconnect);
    this._connection.onreconnecting(this._onDisconnect);

    document.addEventListener("visibilitychange", this._ensureInValidState);
    window.addEventListener("focus", this._ensureInValidState);

    reaction(
      () => this.rootStore.userStore.IsLoggedIn,
      this._ensureInValidState,
      { fireImmediately: true }
    );

    reaction(
      () => this.connected,
      async (connected) => {
        if (connected) {
          await this.rootStore.historyStore.reload();
        }
      }
    );

    this.isReady = true;
  };

  _atachHandlers = () => {
    const {
      clientStore,
      compensationsStore,
      historyStore,
      ratesStore,
      walletStore,
    } = this.rootStore;

    this._subscribe("OnClientChanged", clientStore.onClientChanged);
    this._subscribe("OnSettingChanged", clientStore.onSettingChanged);
    this._subscribe("OnBsvAccountChanged", walletStore.onBsvAccountChanged);
    this._subscribe("OnWeb3AccountChanged", walletStore.onWeb3AccountChanged);
    this._subscribe("OnBalanceChanged", walletStore.onBalanceChange);
    this._subscribe("OnDepositStatusChanged", historyStore.depositStatusUpdate);
    this._subscribe("OnFeeChanged", compensationsStore.setOperationsFees);
    this._subscribe("OnRateChanged", ratesStore.onRateChaged);
    this._subscribe(
      "OnCompensationChanged",
      compensationsStore.setCompensation
    );
    this._subscribe("OnSuccessfulConnection", this._onSuccessfulConnection);
  };

  _ensureInValidState = this.rootStore.singleCall(
    "ensureInValidState",
    flow(function* (rootStore: RootStore) {
      if (document.visibilityState !== "visible") return;

      const {
        userStore: { IsLoggedOut },
        connectionStore: $this,
      } = rootStore;

      if (IsLoggedOut) {
        // if ($this._connection.state !== HubConnectionState.Disconnected) {
        yield $this._connection.stop();
        // }

        return;
      }

      if (
        $this._connection.state === HubConnectionState.Disconnected ||
        $this._connection.state === HubConnectionState.Disconnecting
      ) {
        $this.connected = false;

        try {
          $this.lock();
          yield $this._connection.start();
        } catch {
          $this.unlock();
        }
      }
    })
  );

  _subscribe = (methodName: string, method: (...args: any[]) => void) => {
    this._connection.off(methodName, method);
    this._connection.on(methodName, method);
  };

  _invoke = async <T = any>(methodName: string, ...args: any[]): Promise<T> => {
    if (this._connection.state !== HubConnectionState.Connected) {
      this.rootStore.notificationStore.addNotification("Wallet is offline");

      // @ts-ignore
      return;
    }

    try {
      const result = await this._connection.invoke<T>(methodName, ...args);

      console.log("_invoke", methodName, { result });

      return result;
    } catch (error) {
      const e = isString(error) ? error : `${error}`;
      this.rootStore.notificationStore.addNotification(e);

      // @ts-ignore
      return;
    }
  };

  _onDisconnect = () => {
    this.connected = false;
  };

  _onSuccessfulConnection = () => {
    this.connected = true;
    this.unlock();
  };

  lock = () => {
    if (ownLock === 0) {
      ownLock = 1;
      this.rootStore._lock += 1;
    }
  };

  unlock = () => {
    if (ownLock === 1) {
      ownLock = 0;
      this.rootStore._lock -= 1;
    }
  };

  // address api

  getBalance = (request: TBalanceRequest) =>
    this._invoke<TBalanceResponse>("GetBalance", request);

  getHistory = (request: THistoryRequest) =>
    this._invoke<THistoryResponse>("GetHistory", request);

  getUtxos = (request: TUtxoSetRequest) =>
    this._invoke<TUtxoSetResponse>("GetUtxoSet", request);

  // address api

  // transaction api

  getTransactions = (ids: string[]): Promise<TTransactionsResponse> =>
    this._invoke<TTransactionsResponse>("GetTransactions", ids);

  broadcast = (transaction: string): Promise<boolean> =>
    this._invoke<boolean>("Broadcast", transaction);

  stasBatchInit = (
    request: TBatchBroadcastInitRequest
  ): Promise<TBatchBroadcastInitResponse> =>
    this._invoke<TBatchBroadcastInitResponse>("StasBatchInit", request);

  stasBatchBroadcast = (
    request: TBatchBroadcastSendRequest
  ): Promise<boolean> => this._invoke<boolean>("StasBatchBroadcast", request);

  // transaction api

  // wallet api

  createBsvAccounts = (
    request: TCreateBsvAccountRequest
  ): Promise<TCreateBsvAccountResponse> =>
    this._invoke<TCreateBsvAccountResponse>("CreateBsvAccounts", request);

  createWeb3Account = (
    request: TCreateWeb3AccountRequest
  ): Promise<TWeb3Account> =>
    this._invoke<TWeb3Account>("CreateWeb3Account", request);

  // wallet api

  // client api

  listContacts = (request: TOrderedPageRequest): Promise<TContact[]> =>
    this._invoke<TContact[]>("ListContacts", request);

  addContact = (request: TAddContactRequest): Promise<void> =>
    this._invoke<void>("AddContact", request);

  updateSetting = (request: TUpdateSettingRequest): Promise<void> =>
    this._invoke<void>("UpdateSetting", request);

  // client api

  // ton bridge api

  createTonTransactionPayload = (
    request: TCreateTonTransactionPayloadRequest
  ): Promise<TCreateTonTransactionPayloadResponse> =>
    this._invoke<TCreateTonTransactionPayloadResponse>(
      "CreateTonTransactionPayload",
      request
    );

  // ton bridge api
}
