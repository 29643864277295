import { random } from "lodash";

export const generateRandomNumbers = ({
  count,
  max,
  min,
}: {
  count: number;
  max: number;
  min: number;
}): Set<number> => {
  const genNext = (numbers: Set<number>): Set<number> => {
    if (numbers.size === count) {
      return numbers;
    }

    const number = random(min, max);

    if (numbers.has(number)) return genNext(numbers);

    return genNext(new Set([...Array.from(numbers), number]));
  };

  return genNext(new Set());
};

// most popular answer https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number#answer-175787
export const isNumeric = (str: string) => {
  if (typeof str != "string") return false; // we only process strings!

  return (
    !isNaN(+str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};

export const truncFractions = (
  amount: number,
  maxDecimals: number,
  withSign: boolean
): number => {
  const sign = Math.sign(amount);
  const str = Math.abs(amount).toLocaleString("en", {
    minimumFractionDigits: maxDecimals + 2,
    useGrouping: false,
  });
  const r = [];

  let decimalIdx = -1;
  let decimalsCount = 0;

  for (let i = 0; i < str.length; i++) {
    const ch = str[i];

    if (ch === ".") {
      decimalIdx = i;

      if (maxDecimals === 0) break;
    } else {
      r.push(ch);

      if (decimalIdx > -1) {
        decimalsCount++;
        if (decimalsCount === maxDecimals) break;
      }
    }
  }

  let result = Number(r.join(""));
  result = decimalsCount === 0 ? result : result / 10 ** decimalsCount;

  return withSign ? result * sign : result;
};

export type TCryptoNumber = {
  value: number;
  str: string;
};

export const toFractions = (
  amount: number,
  fractions: number
): TCryptoNumber => {
  const str = `${Math.abs(amount)}`.replace(",", ".");
  const parts = str.split(".");
  const whole = parts[0];
  let frac = parts[1]?.slice(0, fractions) ?? "0";

  while (frac.length < fractions) frac += "0";

  const result = {
    value: Number(whole) * Math.pow(10, fractions) + Number(frac),
    str: whole + frac,
  };

  return result;
};

export const toNanoton = (amount: number) => toFractions(amount, 9);
export const toTonUsdt = (amount: number) => toFractions(amount, 6);
export const toWei = (amount: number) => toFractions(amount, 18);
export const toBsv = (amount: number) => toFractions(amount, 8);
