import { flow, makeAutoObservable, when } from "mobx";
import { RootStore } from "./rootStore";
import { tonService } from "src/services/ton-service";
import { custodialWalletSetting } from "src/types.enums";
import { makePersistable } from "mobx-persist-store";
import { TCryptoNumber } from "src/utils";
import { TCreateTonTransactionPayloadResponse } from "src/types";

let $this: TonStore;

export class TonStore {
  _address?: string;
  _flowInProgress: string | null = null;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: "TonStore",
      properties: ["_address"],
    });

    // @ts-ignore
    window.send = async (amount: TCryptoNumber, memo: string | null) =>
      await this.sendDeposit(amount, memo);
  }

  tonConnectFlow = this.rootStore.singleCall(
    "tonConnectFlow",
    flow(function* (rootStore: RootStore, onCancel: () => void) {
      if ($this._flowInProgress) return;

      $this._flowInProgress = "tonConnectFlow";

      const unsub = yield tonService.connectTonWallet({
        onError: (e) => {
          onCancel();
          $this.showMessage(e);
          $this._flowInProgress = null;
          unsub();
        },
        OnCancel: () => {
          onCancel();
          $this._flowInProgress = null;
          unsub();
        },
        onSuccess: async (token, address) => {
          await $this.onSuccessConnect(token, address);

          $this._address = address;
          $this._flowInProgress = null;
          unsub();
        },
      });
    })
  );

  sendDeposit = this.rootStore.singleCall(
    "sendDeposit",
    flow(function* (
      rootStore: RootStore,
      amount: TCryptoNumber,
      memo: string | null
    ) {
      const response: TCreateTonTransactionPayloadResponse =
        yield rootStore.connectionStore.createTonTransactionPayload({
          Amount: amount.value,
          Memo: memo,
        });

      console.log(response);

      const raw = yield tonService.sendTransaction({
        address: response.ContractAddress,
        payload: response.Payload,
      });

      console.log({ raw });
    })
  );

  private onSuccessConnect = this.rootStore.blockingCall(
    flow(function* (
      rootStore: RootStore,
      accessToken: string,
      address: string
    ) {
      const hasUser = yield rootStore.userStore._setAccessToken(accessToken);

      if (!hasUser) {
        console.error("Identity request failed");

        return;
      }

      yield when(() => rootStore.connectionStore.connected);

      try {
        const {
          walletStore: {
            hasBsvAccount,
            createPkAndSave,
            getAccount,
            createWeb3Account,
          },
        } = rootStore;

        if (!hasBsvAccount) {
          yield createPkAndSave();
          yield rootStore.connectionStore.updateSetting({
            Type: custodialWalletSetting,
            Value: "true",
          });
        }

        if (!getAccount("Ton", "Usdt")) {
          yield createWeb3Account(address, "Ton", "Usdt");
        }
      } catch (error) {
        $this.showMessage(error instanceof Error ? error.message : `${error}`);
      }
    })
  );

  private showMessage = (message: string) =>
    $this.rootStore.notificationStore.addNotification(message);
}

export const getTonStore = (rootStore: RootStore) => {
  if (!$this) {
    console.log("Create Ton Store");
    $this = new TonStore(rootStore);
  }

  return $this;
};
