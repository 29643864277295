import { flow, get, makeAutoObservable, reaction, set, when } from "mobx";
import { RootStore } from "./rootStore";
import { getTimeSpan, TTimeSpan } from "src/utils/time-utils";
import { BridgeCooldown } from "src/services";
import { BsvTokenId, TAllNetworks, TCryptoAsset } from "src/types.enums";
import { TAsset } from "src/types";
import { getAssetKey } from "./walletStore";

export class BridgeStore {
  isReady: boolean = false;

  countdowns: {
    [assetKey: string]: TTimeSpan;
  } = {};
  shouldUpdate: {
    [assetKey: string]: boolean;
  } = {};

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => this.rootStore.userStore.IsLoggedOut,
      (isLoggedOut) => {
        if (!isLoggedOut) return;

        this.countdowns = {};
        this.shouldUpdate = {};
      },
      { fireImmediately: true }
    );
  }

  getCountdown = (
    network: TAllNetworks,
    cryptoAsset: TCryptoAsset
  ): TTimeSpan | undefined =>
    get(this.countdowns, getAssetKey(network, cryptoAsset));

  init = flow(function* (this: BridgeStore) {
    yield when(() => this.rootStore.userStore.isReady);
    yield when(() => this.rootStore.walletStore.isReady);

    const {
      rootStore: {
        walletStore: { listAssets },
      },
    } = this;

    listAssets.forEach((asset) => {
      const {
        config: {
          cryptoAsset,
          scheme: { TokenId },
        },
      } = asset;

      if (cryptoAsset === "Usdxs" || TokenId === BsvTokenId) return;

      setInterval(() => this._updateCountdown(asset), 1000);
    });

    this.isReady = true;
  });

  _updateCountdown = (asset: TAsset) => {
    const {
      rootStore: {
        walletStore: { getAccount },
      },
    } = this;

    const {
      config: { network, cryptoAsset },
      key: assetKey,
    } = asset;

    const web3Account = getAccount(network, cryptoAsset);

    if (!web3Account) return;

    let countdown: TTimeSpan | undefined = undefined;

    if (network === "Bsv" || cryptoAsset === "Usdxs") return;

    if (web3Account.WithdrawPeriodStartedSeconds) {
      const started = new Date(
        web3Account.WithdrawPeriodStartedSeconds * 1000
      ).getTime();
      const endsAt = started + BridgeCooldown;
      const now = new Date().getTime();
      const diff = Math.max(endsAt - now, 0);

      countdown = getTimeSpan(diff);

      if (diff > 0) {
        set(this.shouldUpdate, assetKey, true);
      } else {
        if (get(this.shouldUpdate, assetKey)) {
          set(this.shouldUpdate, assetKey, false);
        }
      }
    }

    set(this.countdowns, assetKey, countdown);
  };
}
