import { createContext, FC, PropsWithChildren, useContext } from "react";
import {
  BridgeStore,
  ClientStore,
  CompensationsStore,
  ConnectionStore,
  HistoryStore,
  MnemonicStore,
  NotificationStore,
  RatesStore,
  RootStore,
  TransactionStore,
  UserStore,
  WalletStore,
} from "src/store";

export const rootStore = new RootStore();
rootStore.init();

const stores = {
  bridgeStore: rootStore.bridgeStore,
  clientStore: rootStore.clientStore,
  connectionStore: rootStore.connectionStore,
  compensationsStore: rootStore.compensationsStore,
  historyStore: rootStore.historyStore,
  mnemonicStore: rootStore.mnemonicStore,
  notificationStore: rootStore.notificationStore,
  ratesStore: rootStore.ratesStore,
  rootStore: rootStore,
  signalRStore: rootStore.connectionStore,
  transactionStore: rootStore.transactionStore,
  userStore: rootStore.userStore,
  walletStore: rootStore.walletStore,
};

export const StoreContext = createContext<{
  bridgeStore: BridgeStore;
  clientStore: ClientStore;
  compensationsStore: CompensationsStore;
  connectionStore: ConnectionStore;
  historyStore: HistoryStore;
  mnemonicStore: MnemonicStore;
  notificationStore: NotificationStore;
  ratesStore: RatesStore;
  rootStore: RootStore;
  signalRStore: ConnectionStore;
  transactionStore: TransactionStore;
  userStore: UserStore;
  walletStore: WalletStore;
}>(stores);

export const StoreProvider: FC<PropsWithChildren> = ({ children }) => (
  <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>
);

export const useStores = () => useContext(StoreContext);
