import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { TRateMessage } from "src/types";
import { RootStore } from "./rootStore";

export class RatesStore {
  bsvRate: number = 1;
  btcRate: number = 1;
  ethRate: number = 1;
  trxRate: number = 1;
  tonRate: number = 1;

  isReady: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      storage: window.localStorage,
      stringify: true,
      name: "RatesStore",
      properties: ["bsvRate", "btcRate", "ethRate", "trxRate", "tonRate"],
    });

    this.isReady = true;
  }

  onRateChaged = ({ CurrencyPair, Rate }: TRateMessage) => {
    if (CurrencyPair === "BsvUsd") this.bsvRate = Rate;
    if (CurrencyPair === "BtcUsd") this.btcRate = Rate;
    if (CurrencyPair === "EthUsd") this.ethRate = Rate;
    if (CurrencyPair === "TrxUsd") this.trxRate = Rate;
    if (CurrencyPair === "TonUsd") this.tonRate = Rate;
  };
}
